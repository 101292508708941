import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import {loginUrl} from "../services/auth";
import { useSelector } from "react-redux";


export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  //const signed = false;
  //const signed = useSelector(state => state.auth.signed);
  const signed = localStorage.getItem('signed');
  
  if (isPrivate && !signed) {
    return <Route path='/' component={() => {
      window.location.href = loginUrl;
      return null;
    }
    }/>;
  }

  return <Route {...rest} component={Component} />; 
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false
};
