import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import FileList from "../pages/FileList";
import Callback from "../pages/Callback";


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Callback} isPrivate/>
      <Route exact path="/oauth2callback" component={Callback}/>

      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={Callback} />
    </Switch>
  );
}
