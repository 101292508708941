import {getToken, getUserInfo, refreshToken} from '../../../services/auth';

 export function login(authCode) {
      return function (dispatch){        
        return getToken(authCode)
            .then(res => {
                const authToken = res.data;
                console.log("token: "+JSON.stringify(authToken));
                getUserInfo(authToken).then(res =>{
                  const userInfo = res.data;
                  console.log("userInfo: "+ JSON.stringify(userInfo));
                  //this.props.login(Object.assign(authToken,userInfo));
                  var info=Object.assign(authToken,userInfo);
                  localStorage.setItem('signed', true);
                  console.log('info '+JSON.stringify(info));
                  localStorage.setItem('refresh_token', authToken.refresh_token);
                  dispatch({
                    type: "@auth/LOGIN",
                    info
                  })
                });
              }
            )
            .catch(error => {
                console.log("error.data: "+ JSON.stringify(error));
            });
    }
  }
  
  export function autoLogin(refresh_token){
    return function (dispatch){
      return refreshToken(refresh_token)
      .then(res =>{
        const authToken = res.data;
        console.log("token: "+JSON.stringify(authToken));
        getUserInfo(authToken).then(res =>{
          const userInfo = res.data;
          console.log("userInfo: "+ JSON.stringify(userInfo));
          var info=Object.assign(authToken,userInfo);
          console.log('info '+ JSON.stringify(info));
          dispatch({
            type: "@auth/LOGIN",
            info
          })
        });
      }
      ).catch(error => {
        console.log("error.data: "+ JSON.stringify(error));
    });
    }
  }

  export function userLogout(){
    return function (dispatch){
          localStorage.clear();
          dispatch({
            type: "@auth/LOGOUT"
          })
        }
  }