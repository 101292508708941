import React from 'react';
import axios from 'axios';
import { Popover, Button, Table, Input, Spin} from 'antd';
import { DownloadOutlined, PoweroffOutlined } from '@ant-design/icons';
import { userLogout } from "../store/modules/auth/actions";
import './FileList.css';
import { connect } from 'react-redux';

function mapStateToProps (state) {
  return { auth: state.auth }
}
function mapDispatchToProps (dispatch) {
  return {
    userLogout: ()=> dispatch(userLogout())
  }
}

const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Bangkok');

class FileList extends React.Component {
  state = {
    files: [], filterTable: null, loading: true, prevAuthState: {}, webUser: true
  }

  search = value => {
    const { files } = this.state;
    console.log("PASS", { value });

    const filterTable = files.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );

    this.setState({ filterTable });
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'RCF_ID',
      key: 'RCF_ID',
    },
    {
      title: 'File Date',
      dataIndex: 'RCF_DATE',
      key: 'RCF_DATE',
    },
    {
      title: 'File Name',
      dataIndex: 'RCF_NAME',
      key: 'RCF_NAME',
    },
    {
      title: 'Create Date',
      dataIndex: 'RCF_CREATE_DATE',
      key: 'RCF_CREATE_DATE',
    },
    {
      title: '',
      dataIndex: 'RCF_DOWNLOAD_LINK',
      key: 'RCF_DOWNLOAD_LINK',
      render: (text,row) => <Button href={text} onClick={(e)=>this.handleClick(row)} type="primary" shape="round" icon={<DownloadOutlined />}>
                      Download
                    </Button>
    }
  ];

  handleClick = (row) => {
    let body={
      RCF_ID: row.RCF_ID,
      id_token: this.props.auth.id_token
    }
    console.log('download link was clicked. RCF_ID: '+ row.RCF_ID);
    axios.post(process.env.REACT_APP_API_DOWNLOAD_COUNT_URL,
      body,
      { 
        headers: {
        'Authorization': 'Bearer '+this.props.auth.access_token
      }
      }
      )
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
  };

  handleLogout = () => {
   console.log("user click log out");
   this.props.userLogout();
  };
  
  componentDidMount(){
    this.setState({prevAuthState: {}})
  }

  //this will trigger after redux state change when user logged in to prevent fetch data on empty state
  componentDidUpdate() {
    if(this.state.prevAuthState !== this.state.auth){
    this.setState({prevAuthState: this.state.auth})
    axios.post(process.env.REACT_APP_API_VIEW_REPORT_URL,
      {
        id_token: this.props.auth.id_token
      },
      {
        headers: {
        'Authorization': 'Bearer '+this.props.auth.access_token
        }
      }
      )
      .then(res => {
        //console.log('signed '+ JSON.stringify(this.props.auth.signed));
        //console.log('Authorization '+ this.props.auth.access_token);
        console.log(res);
        const files = res.data.result;
        this.setState({ files, loading: false, webUser: true });
      }).catch(error =>{
        console.error(error.response.data);
        if(error.response.status===403){
          this.setState({webUser: false});
        }
      })
  }
  }
  render() {
    if(this.state.webUser===true){
    this.state.files.map(file => {
      file.RCF_DATE=moment(file.RCF_DATE).format("YYYY-MM-DD");
      file.RCF_CREATE_DATE=moment(file.RCF_CREATE_DATE).format();
    });
    const { files, filterTable, loading} = this.state;
    let display;
    if(loading){
      display=<Spin size="large" style={{ height: '50%' }}/>
    }else{
      display=<Table dataSource={filterTable == null ? files : filterTable} columns={this.columns} />;
    }
    return (
            <div className="userInfo">
              <div style={{display:"inline", 
                          textTransform: "uppercase",
                          fontWeight: "bold"}}>{this.props.auth.name}</div>
              <div style={{display:"inline"}}>&nbsp;</div>
              <div style={{display:"inline"}}>
                <Popover content="sign out">
                  <Button
                  type="primary"
                  icon={<PoweroffOutlined />}
                  size="small"
                  onClick={(e)=>this.handleLogout()}
                  />
                </Popover>
              </div>
            <div className="container">
              <Input.Search
                style={{ margin: "0 0 10px 0" }}
                placeholder="Search by..."
                enterButton
                onSearch={this.search}
              />
              <div>
                {display}
              </div>
            </div>
            </div>
           );
  }
  if(this.state.webUser===false){
    return(
      <div style={{padding: "20px 20px"}}><h3>Sorry, You have no permission to access this page. 
      <a href="#" onClick={(e)=>this.handleLogout()}> Click here</a> to exit.</h3></div>);
  }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FileList)