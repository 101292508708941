import axios from "axios";

const config = {
    baseUri: process.env.REACT_APP_CLIENT_AUTHEN_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    callbackUri: process.env.REACT_APP_CLIENT_CALLBACK_URI
}
const loginUrl=`${config.baseUri}/login?response_type=code&client_id=${config.clientId}&redirect_uri=${config.callbackUri}`;

function getAuthCode(href){
    return new URLSearchParams(new URL(href).search).get('code');
};

function getToken(authCode){
    return new Promise((resolve,reject)=>{
        const options={
            url: `${config.baseUri}/oauth2/token`,
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic "+Buffer.from(config.clientId+':'+config.clientSecret).toString('base64')
            },
            data: `grant_type=authorization_code&client_id=${config.clientId}&code=${authCode}&redirect_uri=${config.callbackUri}`
        };
        axios(options)
        .then(resp =>{resolve(resp)})
        .catch(error =>{reject(error.response)});
    });
}

function getUserInfo(token){
    return new Promise((resolve,reject)=>{
        const options={
            url: `${config.baseUri}/oauth2/userInfo`,
            method: 'GET',
            headers: {
                "Authorization": `${token.token_type} ${token.access_token}`
            }
        };
        axios(options)
        .then(resp =>{resolve(resp)})
        .catch(error =>{reject(error.response)});
    });
}
function refreshToken(refresh_token){
        return new Promise((resolve,reject)=>{
            const options={
                url: `${config.baseUri}/oauth2/token`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Basic "+Buffer.from(config.clientId+':'+config.clientSecret).toString('base64')
                },
                data: `grant_type=refresh_token&client_id=${config.clientId}&refresh_token=${refresh_token}`
            };
            axios(options)
            .then(resp =>{resolve(resp)})
            .catch(error =>{reject(error.response)});
        });
}

export {loginUrl, getAuthCode, getToken, getUserInfo, refreshToken};