const INITIAL_STATE = {
};
export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
      case "@auth/LOGIN":
        return Object.assign({},
          action.info,
          { signed: true })
      case "@auth/LOGOUT":
        return { signed: 'logout' };
      default:
        return state;
    }
}