import React from 'react';
import {getAuthCode, getToken, getUserInfo, refreshToken} from '../services/auth';
import Route from "../routes/Route";
import FileList from "./FileList";
import { login, autoLogin, logout } from "../store/modules/auth/actions";
import { connect } from 'react-redux';

function mapStateToProps (state) {
  return { auth: state.auth }
}
function mapDispatchToProps (dispatch) {
  return {
    login: authToken => dispatch(login(authToken)),
    autoLogin: refreshToken => dispatch(autoLogin(refreshToken))
  }
}

const config = {
  baseUri: process.env.REACT_APP_CLIENT_AUTHEN_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  logoutUri: process.env.REACT_APP_CLIENT_LOGOUT_URI
}

class Callback extends React.Component {
  
  componentDidMount () {

    if(!localStorage.getItem('signed')){
      console.log('get token by auth_code ');
      let authCode=getAuthCode(window.location.href);
      this.props.login(authCode);
    }else{
      console.log('get token by refresh_token ');
      this.props.autoLogin(localStorage.getItem('refresh_token'));
  }
}
  
  render(){
    console.log("signed: "+this.props.auth.signed);
    if(this.props.auth.signed===undefined){
     return null
    }
    if(this.props.auth.signed==='logout'){
      window.location.href = `${config.baseUri}/logout?client_id=${config.clientId}&logout_uri=${config.logoutUri}`
    }
    if(this.props.auth.signed===false){
      return (<Route to="/" component={this} isPrivate/>);
    }
    return(<Route to="/" component={FileList}/>);
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Callback)

